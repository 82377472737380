<template>
  <div class="all-notifications">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div v-if="!!newNotifications.length" class="notifications new">
          <div
            v-for="notification in newNotifications"
            class="notification"
            :key="notification.id"
          >
            <div class="left">
              <div v-if="notification.unread" class="status"></div>
              <span class="message" v-html="notification.message"></span>
            </div>
<!--            <router-link-->
<!--              v-else-if="notification.link.startsWith('/')"-->
<!--              class="left"-->
<!--              :to="notification.link"-->
<!--            >-->
<!--              <div v-if="notification.unread" class="status"></div>-->
<!--              <spanc lass="message" v-html="notification.message"></spanc>-->
<!--            </router-link>-->
<!--            <a v-else class="left" :href="notification.link">-->
<!--              <div v-if="notification.unread" class="status"></div>-->
<!--              <span class="message" v-html="notification.message"></span>-->
<!--            </a>-->
            <div class="right">
              <span class="timestamp">
                {{ formatTime(notification.timestamp) }}
                <!-- new Date(+notification.timestamp).toLocaleString() -->
              </span>
            </div>
          </div>
          <div class="splitter">New Notifications</div>
        </div>
        <div class="notifications">
          <div
            v-for="notification in notifications"
            class="notification"
            :key="notification.id"
          >
            <div class="left">
              <div v-if="notification.unread" class="status"></div>
              <span class="message" v-html="notification.message"></span>
            </div>
<!--            <router-link-->
<!--              v-else-if="notification.link.startsWith('/')"-->
<!--              class="left"-->
<!--              :to="notification.link"-->
<!--            >-->
<!--              <div v-if="notification.unread" class="status"></div>-->
<!--              <span class="message" v-html="notification.message"></span>-->
<!--            </router-link>-->
<!--            <div v-else class="left" :href="notification.link">-->
<!--              <div v-if="notification.unread" class="status"></div>-->
<!--              <span class="message" v-html="notification.message"></span>-->
<!--            </div>-->
            <div class="right">
              <span class="timestamp">
                {{ formatTime(notification.timestamp) }}
              </span>
            </div>
          </div>
        </div>
        <div class="button-wrapper">
          <diyobo-button type="primary" txt="Load More" @click="loadMore" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DiyoboButton from "../components/DiyoboButton.vue";
import moment from "moment";
// import { formatDate, formatTime } from "@/helpers/input-formats.js";
import dateformat from "dateformat";

export default {
  name: "notifications",
  components: {
    DiyoboButton,
  },
  head() {
    return {
      title:
        "Never Miss an Experience Again • Event Notifications",
      meta: [
        {
          name: "description",
          content:
            "Never miss an event or lose your tickets again. INCREDEVENT will keep your tickets safe and remind you of upcoming events. Turn on your notifications!",
        },
        {
          name: "keywords",
          content: "Events, Tickets, Directory, Promotion",
        },
        {
          name: "author",
          content: "INCREDEVENT",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
      ],
    };
  },
  data() {
    return {
      offset: 20,
      notifications: [],
      newNotifications: [],
      loading: false,
    };
  },
  methods: {
    onNewNotification(notification) {
      this.newNotifications.unshift(notification);
      this.$notifications.markRead();
    },
    loadMore() {
      if (!this.loading) {
        this.loading = true;

        this.$axios
          .get("/notifications/list", {
            params: {
              offset: this.offset,
              type: this.$store.state.site,
            },
          })
          .then((response) => {
            const data = response.data;
            const notifications = data.notifications.map((n) => {
              n.link = this.$notifications.localizeLink(n.link);

              return n;
            });

            this.notifications.push(...notifications);
            this.offset += notifications.length;
            this.loading = false;
          });
      }
    },
    formatDate(dateStr) {
      return dateformat(parseInt(dateStr), "ddd, mmm dS, yyyy");
    },
    formatTime(dateStr) {
      // return
      return moment(dateStr).fromNow();
      // dateformat(parseInt(dateStr), "hh:MM TT");
    },
  },
  created() {
    console.log('(/notifications/list)');
    this.$store.commit("setTitle", "Notifications");
    this.$store.commit(
      "setHelpVideoSrc",
      "https://youtube.com/embed/PmI8qqKHxjs"
    );
    this.$axios
      .get("/notifications/list", { params: { type: this.$store.state.site } })
      .then((response) => {
        this.notifications = response.data.notifications.map((n) => {
          n.link = this.$notifications.localizeLink(n.link);

          return n;
        });

        this.$notifications.markRead();

        this.$notifications.watch(this.onNewNotification);
      });
  },
  destroyed() {
    this.$notifications.unwatch(this.onNewNotification);
  },
};
</script>

<style lang="less" scoped>
.content-inner {
  padding-top: 40px !important;
  min-height: 90vh; //change
}

.notifications {
  display: flex;
  flex-direction: column;
  width: inherit;
  margin: auto;

  .notification {
    display: flex;
    justify-content: space-between;
    width: inherit;
    max-width: 800px;
    margin: 5px auto;
    padding: 10px 20px;
    border: 1px solid var(--input-border);
    border-radius: 8px;
    // background-image: linear-gradient(#1f1f1f, #161616);
    @media screen and (max-width: 415px) {
      justify-content: space-evenly;
      align-items: center;
      margin: 8px 0;
      padding: 8px;

      .timestamp {
        font-size: 12px;
      }

      .message {
        font-size: 12px;
      }
    }

    @media screen and (max-width: 375px) {
      .message {
        font-size: 10px;
      }
    }

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: @colors[text];

      .status {
        margin-right: 1em;
        min-width: 13px;
        height: 13px;
        border-radius: 100%;
        background-color: #53b85a;
      }
    }

    .right {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    @media screen and (max-width: 550px) {
      width: -webkit-fill-available;
    }
  }

  .splitter {
    display: flex;
    justify-content: center;
    position: relative;
    font-weight: bold;
    padding: 10px;

    &::before {
      content: " ";
      display: block;
      position: absolute;
      top: 50%;
      right: 58%;
      width: 25%;
      border-bottom: 2px solid @colors[primary-green];
      z-index: -1;
    }

    &::after {
      content: " ";
      display: block;
      position: absolute;
      top: 50%;
      left: 58%;
      width: 25%;
      border-bottom: 2px solid @colors[primary-green];
      z-index: -1;
    }
  }

  @media screen and (max-width: 550px) {
    width: -webkit-fill-available;
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
</style>
